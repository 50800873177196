/* @import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap"); */

/* @font-face {
  font-family: pokemonFontRegular;
  src: url("../fonts/MightySouly-lxggD.ttf");
  font-display: swap;
}

@font-face {
  font-family: pokemonFontLight;
  src: url("../fonts/MightySouly-lxggD.ttf");
  font-display: swap;
}

@font-face {
  font-family: pokemonFontBold;
  src: url("../fonts/MightySouly-lxggD.ttf");
  font-display: swap;
} */

.fontsRegular {
  font-family: monospace;
}

.fontsLight {
  font-family: monospace;
}

.fontsBold {
  font-family: monospace;
}

* {
  scroll-behavior: smooth;
}

.Home {
  text-align: center;
}

.Home-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.overflowHidden {
  overflow: hidden;
}

.overflow-x-Hidden {
  overflow-x: hidden;
}

.Home-link {
  color: #61dafb;
}

.order-list-navbar-div {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin-right: 1vw;
}

.order-list-navbar-ul {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  align-items: flex-end;
}

.order-list-navbar-li-add-incoming {
  margin-left: 20px;
  margin-right: 20px;
}

.order-list-navbar-li {
  margin-left: 40px;
  position: relative;
  cursor: pointer;
  list-style: none;
  font-weight: 600;
}

.order-list-navbar-li::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #bc63ff;
  transition: width 0.3s;
}

.order-list-navbar-li:hover::before {
  width: 100%;
}

.link-navbar {
  text-decoration: none;
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn,
.navbar-font {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 0.95em;
  font-weight: 600;
  padding: 0;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #3e4552;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content-Projects {
  display: none;
  /* position: fixed; */
  background-color: #3e4552;
  min-width: 160px;
  z-index: 1;
}

.traductionPojects {
  padding: 15px 50px 15px 20px !important;
  position: relative !important;
  background-color: #3e4552 !important;
  color: white !important;
}

.paddingLastLanguage {
  padding: 0px 50px 15px 20px !important;
}

.link-navbar.dropdownSize {
  font-size: 0.6em;
}

/* Montrer le contenu du dropdown lors du survol */
.dropdown-content,
.dropdown-content-Projects {
  display: block;
}

.dropdown-content a,
.dropdown-content-Projects a,
.dropdown-content-Projects button {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  position: relative;
}

.dropdown-content a::after,
.dropdown-content-Projects a::after,
.dropdown-content-Projects button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: rgba(188, 99, 255, 1);
  transition: width 0.3s ease;
}

/* a la fin de l'animation, la bordure reste visible */
.dropdown-content a:hover::after,
.dropdown-content-Projects a:hover::after,
.dropdown-content-Projects button:hover::after {
  width: 100%;
}

/* PAGE ACCEUIL ABOUT ME */

.aboutMe {
  display: grid;
  grid-template-areas: "left right";
  padding: 20px 0 0 0;
}

.aboutMeRightPart {
  grid-area: right / right / right / right;
  margin-left: 30px;
}

.imagePlacement {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.imageSize {
  max-width: 35vw;
  margin: 0 30px 0 0;
}

.aboutMeText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 30px;
}

.removeMargin {
  margin: 10px;
}

.typingAnimation {
  font-weight: 600;
}

.font-weight-100 {
  font-weight: 100 !important;
  font-size: 0.6em !important;
}

.presentationText {
  text-align: start;
  padding: 0 0 0 30px;
}

.traitSeparator {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.traitSeparator::before {
  content: "";
  position: absolute;
  bottom: 0;
  border: 2px solid transparent;
  /* animation: borderAnimation 3s infinite linear; */
  width: 100%;
  border-color: #bc63ff;
}

/* @keyframes borderAnimation {
  0% {
    border-color: rgb(255, 0, 0);
  }
  25% {
    border-color: rgb(0, 255, 0);
  }
  50% {
    border-color: rgb(188, 99, 255);
  }
  75% {
    border-color: rgb(255, 255, 0);
  }
  100% {
    border-color: rgb(255, 0, 255);
  }
} */

.presentationFlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.RedirectionIcon {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0 30px;
}

.iconLinkedin {
  margin-right: 20px;
}

.btnDiscover {
  display: flex;
  justify-content: flex-start;
  margin: 30px 0 0 30px;
}

.btnStyleDiscover {
  border-radius: 5%;
  padding: 15px 50px 15px 50px;
  cursor: pointer;
  background: rgba(188, 99, 255, 1);
  transition: all 1s;
  color: white;
  font-weight: bold;
  font-size: medium;
}

.btnStyleDiscover:hover,
.btnStyleDiscoverProject:hover {
  transition: all 1s;
  background: rgba(188, 99, 255, 0.6);
}

.aboutMePartDescription {
  display: flex;
  flex-wrap: wrap;
  background-color: #464d5a;
  width: 100%;
  height: 100%;
}

.aboutMeTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

.paragrapheAboutMe {
  font-size: large;
  text-align: left;
  padding: 0 25px 0 45px;
}

.sizeArthur {
  font-size: larger;
  width: max-content;
}

.width {
  width: 100%;
}

.inherit {
  width: inherit;
}

.titleName {
  display: flex;
  flex-wrap: wrap;
  width: 350px;
  align-items: center;
  flex-direction: row;
  margin-left: 3vw;
}

.rectangleGlobal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.backbtnUnityPage {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.titleUnityPage {
  margin-top: 10px;
}

.rectangleTool {
  position: relative;
  padding: 15px 50px 15px 25px;
  background-color: #282c34;
  border-radius: 4%;
  margin: 5px 30px 50px;
}

.iconSpace,
.iconSpace2 {
  margin-right: 10px;
}

.sizeIconCPlus {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.takeSize {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;
  position: relative;
  overflow-x: hidden;
}

.notifLangage {
  position: absolute;
  right: -10px;
  top: -10px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  background-color: #bc63ff;
  padding: 2px 10px 2px 10px;
  border-radius: 50%;
  animation: scaleNotif 2s linear infinite;
}

.infoIcon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
  animation: scaleNotif 2s linear infinite;
}

.iconInfoWidth {
  width: 2em;
}

@keyframes scaleNotif {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* PAGE PROJETS */
.divGlobalProjects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  max-width: 36em;
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  scroll-padding: 0;
}

.flex-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.divGlobalProjects::-webkit-scrollbar {
  display: none;
}

.divGlobalProjects::before,
.divGlobalProjects::after {
  content: "";
  width: 17px;
}

.projects {
  width: calc(100% - 30%);
  background: rgba(255, 255, 255, 0.1);
  padding: 5%;
  border-radius: 3%;
  margin: 5%;
  scroll-snap-align: center;
  box-sizing: border-box;
  flex: 0 1 auto;
  /* width: 100%; */
}

.sizeProjectIMG {
  width: 90%;
}

.sizeProjectIMG2 {
  width: 50%;
}

.sizeProjectIMG3 {
  width: 70%;
}

.flexIMG {
  display: flex;
  justify-content: center;
}

.HelluvaRevengeTitle {
  color: #bc63ff;
}

.pDescription {
  font-size: 0.8em;
}

.btnDiscoverProject {
  display: flex;
  justify-content: flex-start;
}

.btnStyleDiscoverProject {
  border-radius: 5%;
  padding: 15px 50px 15px 50px;
  cursor: pointer;
  background: rgba(188, 99, 255, 1);
  transition: all 1s;
  color: white;
  font-weight: bold;
  font-size: medium;
}

/* Download CV */

.btnDownloadCV {
  background-color: transparent;
  border: none;
}

/* Navbar Project Vertical */

.navbarProject {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.navbarHome {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.navbarHome-add-incoming {
  flex-direction: column;
  align-content: flex-end;
}

.categoryProject {
  border-radius: 5%;
  padding: 15px 50px 15px 30px;
  cursor: pointer;
  background: rgba(188, 99, 255, 0.8);
  transition: all 1s;
  color: white;
  font-weight: bold;
  font-size: medium;
  margin: 30px 0 0 30px;
  z-index: 100;
}

.backgroundUnderCategory:hover {
  animation: scaleCategoryGames 2s linear infinite;
}

@keyframes scaleCategoryGames {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.sizeCategoryProjectGames {
  font-size: medium;
  margin: 10% 0 5% 0;
}

.noColorC,
.noColor,
.noColorCustomEngine,
.noColorPython,
.noColorLUA,
.noColorCSharp,
.noColorCPlus,
.noColorNavbar,
.noColorCeltic,
.noColorEko,
.noColorPaiement,
.noColorGoudGuys,
.noColorBAImmobilier,
.noColorReact {
  color: white;
  text-decoration: none;
}

#navbarHome {
  display: none;
}

.btnNavbarProjects {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 0;
}

.dropdownProjectPadding {
  padding: 0;
}

@keyframes fadeIn {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 300px;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    max-height: 300px;
    max-width: 300px;
    opacity: 1;
  }
  to {
    max-height: 0;
    max-width: 0;
    opacity: 0;
  }
}

@keyframes fadeBongo {
  0% {
    max-height: 0;
    opacity: 0;
  }
  20% {
    max-height: 150px;
    opacity: 0.5;
  }
  40% {
    max-height: 300px;
    opacity: 1;
  }
  60% {
    max-height: 300px;
    opacity: 1;
  }
  80% {
    max-height: 150px;
    opacity: 0.5;
  }
  100% {
    max-height: 0;
    opacity: 0;
  }
}

.sizeMySql {
  font-size: medium;
}

/* Load.js */

.pikachu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-color: #3e4552;
  transform: scaleX(-1);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: top;
  overflow: hidden;
  background-color: #3e4552;
}

.bubbleStyle {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 120px -50px 0 -0;
}

.textStyle {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 190px 0px 0 0;
  font-weight: bold;
}

.fade-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(22, 26, 36);
  opacity: 0;
  animation: fadeInAnimation 1s ease forwards;
  z-index: 100;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(22, 26, 36);
  opacity: 1;
  animation: fadeOutAnimation 1s ease forwards;
  z-index: 100;
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Contact Me */

.flex-contact {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #353a44;
  border-radius: 3%;
  padding: 15px 30px 15px 30px;
}

.align-form-contact {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.flex-contact-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flex-contact-div.margin-contact-div {
  margin-left: 30px;
}

.marge-contact-play {
  margin-left: 30px;
}

.contactMeFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.boxCoordonneeContact {
  border-radius: 5%;
  padding: 15px 50px 15px 50px;
  cursor: pointer;
  background: rgba(188, 99, 255, 1);
  color: white;
  margin-right: 5%;
}

input[type="mail"],
input[type="text"],
button[type="submit"],
textarea {
  border-radius: 3%;
  padding: 15px 50px 15px 20px;
  background: rgba(188, 99, 255, 1);
  color: black;
  border: none;
  outline: none;
  font-family: pokemonFont;
  font-weight: bold;
  z-index: 1;
  width: -webkit-fill-available;
}

input::placeholder,
textarea::placeholder,
button::placeholder {
  color: black;
  font-weight: bold;
}

.flex-part-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.margin-submit-form-contact,
.margin-incoming-title {
  margin-bottom: 5%;
}

.flex-row-part-form-nom-prenom {
  display: flex;
  flex-direction: row;
}

.flex-row-part-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.responsive-text-btn {
  font-size: 0.5em;
}

.overflowPopup {
  overflow: hidden;
}

.noACss {
  color: unset;
  text-decoration: unset;
}

@media (max-width: 1500px) {
  .imgProg {
    display: none;
  }
}

/* PAGE HOME FORMAT TELEPHONE */
@media (max-width: 900px) {
  .aboutMe {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .aboutMeRightPart {
    display: none;
  }
  .imgProg {
    display: flex;
  }
}

@media (max-width: 650px) {
  .order-list-navbar-ul {
    flex-direction: row;
    align-items: center;
  }
  .btnStyleDiscoverProject {
    padding: 15px;
  }
}

@media (max-width: 800px) {
  .fixBackBtnUnity {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: large;
  }
  .btnNavbar,
  .btnNavbarProject,
  .fixMargin {
    margin: 5% 0 0 0 !important;
  }
  .takeSize {
    margin: 5%;
    height: auto !important;
  }
  .titleName {
    justify-content: center;
  }
  .navbarHome {
    display: none;
    max-height: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 1%;
    height: auto;
    z-index: 1000;
    width: auto;
  }
  .categoryProject {
    padding: 5px 20px 5px 15px;
    font-size: small;
  }
  .dropbtn,
  .navbar-font {
    font-size: small;
  }
  .bubbleHomeNavbar {
    display: block !important;
  }
  .btnDownloadCV {
    display: block;
  }
  .order-list-navbar-li {
    font-size: small;
  }
  .bubbleHomeNavbar {
    display: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: rgba(188, 99, 255, 0.8);
    border-radius: 10% 30% 50% 70%;
    position: absolute;
    bottom: 0;
    right: 0;
    position: fixed;
  }
  .bubbleHomeNavbarBtn {
    display: none;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 10% 30% 50% 70%;
    position: absolute;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 10000;
  }
  .traitSeparator {
    display: none;
  }
  .order-list-navbar-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(188, 99, 255, 0.8);
    border-radius: 2%;
    padding-bottom: 10%;
  }
  .order-list-navbar-li {
    display: flex;
  }
  .dropdown-content {
    position: relative;
  }
  .dropdown-content-Projects {
    position: relative;
  }
  .link-navbar,
  .dropdown {
    margin: 5% 0 5% 0;
  }
  .aboutMeRightPart {
    display: flex;
  }
  .imageSize {
    margin: 0;
    max-width: 55vw;
  }
  .imagePlacement {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .rectangleTool {
    position: relative !important;
  }
  .overflowPopup {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 1050px) {
  .divGlobalProjects {
    scroll-snap-type: none;
  }
  .top-margin-btn-icon {
    display: none !important;
  }
}

@media (min-width: 800px) {
  .navbarHome {
    display: flex !important;
    max-height: max-content !important;
  }
  .btnDownloadCV {
    display: none;
  }
}

@media (max-width: 1500px) {
  .aboutMe {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .iframeYoutube {
    width: auto !important;
    height: auto !important;
  }
}

/* PAGE CONTACT FORMAT TELEPHONE */
@media (max-width: 872px) {
  .flex-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .display-remove {
    display: none;
  }
  .flex-row-part-form-nom-prenom {
    display: block;
  }
  .iconSpace2 {
    margin-right: 0;
  }
  .flex-contact-div.margin-contact-div {
    margin-left: 0;
  }
  .rectangleGlobal {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    text-align: initial;
  }
}

/* PAGE PROJECTS FORMAT TELEPHONE */

@media (max-width: 1483px) {
  .navbarProject {
    display: none;
    max-height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1%;
    height: auto;
    z-index: 100;
  }
  .categoryProject {
    padding: 5px 20px 5px 15px;
    font-size: small;
  }
  .dropbtn,
  .navbar-font {
    font-size: small;
  }
  .bubbleProjectNavbar {
    display: block !important;
  }
  .btnDownloadCV {
    display: block;
  }
  .order-list-navbar-li {
    font-size: small;
  }
  .overflowNavbarProjects {
    overflow: scroll;
  }
  .margin-incoming-title {
    margin: 0;
  }
}

@media (min-width: 1483px) {
  .navbarProject {
    display: flex !important;
    max-height: max-content !important;
  }
  .btnDownloadCV {
    display: none;
  }
}

@media (min-width: 1483px) and (max-width: 1531px) {
  .navbarProject {
    display: none;
    max-height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1%;
    height: auto;
    z-index: 100;
  }
  .categoryProject {
    padding: 5px 20px 5px 15px;
    font-size: small;
  }
  .dropbtn,
  .navbar-font {
    font-size: small;
  }
  .bubbleProjectNavbar {
    display: none !important;
  }
  .btnDownloadCV {
    display: block;
  }
  .order-list-navbar-li {
    font-size: small;
  }
  .overflowNavbarProjects {
    overflow: scroll;
  }
}

.bubbleProjectNavbar {
  display: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: rgba(188, 99, 255, 0.8);
  border-radius: 10% 30% 50% 70%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.borderRGBForm {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: initial;
}

.borderRGBForm:focus-within::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid transparent;
  box-sizing: border-box;
  border-radius: 3%;
  animation: borderAnimation 3s infinite linear;
}

.submitForm {
  cursor: pointer;
}

.marginDownloadIcon {
  margin-left: 5%;
}

.preview-div {
  position: relative;
  width: 90%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.displayBtn {
  display: flex;
}

/* .preview-div:hover .preview-video {
  opacity: 1;
} */

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.noMargin {
  margin: 0;
}

.relativePosition {
  position: relative;
}

.gifForm {
  position: relative;
  height: 150px;
  z-index: 1;
}

.notification {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #bc63ff;
  opacity: 0.9;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-size: 16px;
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
}

.notification.hidden {
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .notification {
    left: 10px;
    right: 10px;
    width: auto;
    max-width: 90%;
  }
}

.centerLoad {
  display: flex;
  justify-content: center;
}

.green {
  color: rgb(0, 255, 0);
}

/* Game Jam */

.grid-gamejam {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* a mettre sur (3, 1fr) quand j'aurai plus de game jam à mettre */
  grid-template-rows: repeat(
    1,
    1fr
  ); /* a mettre sur (3, 1fr) quand j'aurai plus de game jam à mettre */
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 1vw;
}

.grid-gamejam2 {
  display: grid;
  grid-template-columns: repeat(
    1,
    1fr
  ); /* a mettre sur (3, 1fr) quand j'aurai plus de game jam à mettre */
  grid-template-rows: repeat(
    1,
    1fr
  ); /* a mettre sur (3, 1fr) quand j'aurai plus de game jam à mettre */
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 1vw;
}

.colorPurple {
  color: #c663ff;
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .grid-gamejam,
  .grid-gamejam2,
  .grid-incoming {
    grid-template-columns: repeat(1, 1fr);
    transition: all 1s;
  }
  .marge-contact-play {
    margin: 0 20px;
  }
}

.underline {
  text-decoration: underline;
}

.modernEnvelop {
  background: rgba(66, 73, 85, 0.5);
  padding: 1vw;
  text-align: center;
  border-radius: 1vw;
}

.text-align-left {
  text-align: left;
}

.padding-1vw {
  padding: 1vw;
}

.font-size-large {
  font-size: large;
}

.backgroundPopupGameJam {
  background: rgba(51, 57, 69, 0.5);
}

.text-align-center {
  text-align: center;
}

.absolute {
  position: absolute;
}

.z-index {
  z-index: 100;
}

#blur.sizeProjectIMG {
  display: flex;
  align-items: center;
}

.align-items-center {
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.scroll-bar-navbar-projects {
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scrollbar-width: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #282c34;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c663ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btnNavbarProject {
  background: transparent;
  border: transparent;
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: medium;
}

.btnNavbar {
  background: transparent;
  border: transparent;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.add-no-margin-incoming {
  justify-content: end;
  margin: 0;
}

.overflow-y-incomming {
  max-height: 250px;
  overflow-y: scroll;
}

.grid-incoming {
  display: grid;
  grid-template-columns: repeat(
    1,
    1fr
  ); /* a mettre sur (3, 1fr) quand j'aurai plus de game jam à mettre */
  grid-template-rows: repeat(
    1,
    1fr
  ); /* a mettre sur (3, 1fr) quand j'aurai plus de game jam à mettre */
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 1vw;
}

.position-fixed {
  position: fixed;
}

.display-flex-start-navbarProjects-Btn {
  width: 100%;
  display: flex !important;
  justify-content: flex-start;
}

.xx-large {
  font-size: xx-large;
}

.pos-btn-video {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
}

.center-navbar-projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-scroll-up-projects {
  display: flex;
}

.top-margin-btn-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 3% 0;
}

.sizeProjectIMG3 {
  width: inherit;
}

/* Surbrillance */

.surbrillance:hover {
  box-shadow: 0 0 10px 3px rgba(188, 99, 255, 0.7);
  transition: box-shadow 0.5s ease;
}

.surbrillance {
  transition: box-shadow 0.5s ease;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.padding-int:hover {
  padding: 3%;
  transition: padding 1s;
}

.padding-int {
  padding: 0%;
  transition: padding 1s;
}

.no-margin-top {
  margin-top: 0 !important;
}
